import React from 'react'
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import FlexBox, { Justification } from '../../components/FlexBox/FlexBox'
import FullWidthSection, { PaddingOption } from '../../components/FullWidthSection/FullWidthSection'
import './CardContainer.scss'

const CardContainer: React.FunctionComponent = ({ children }) => (
  <FullWidthSection className="card-container" paddingTop={PaddingOption.SMALL} paddingBottom={PaddingOption.SMALL}>
    <ContentContainer>
      <FlexBox className="card-container__flex" justify={Justification.EVENLY} wrap={false}>
        {children}
      </FlexBox>
    </ContentContainer>
  </FullWidthSection>
)

export default CardContainer
