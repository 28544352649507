import React from 'react'

import FlexBox, { Justification } from '../FlexBox/FlexBox'
import ImageContainer, { RowNumber } from '../ImageContainer/ImageContainer'

import institutionLogoNebraska from './images/institutionLogoNebraska.png'
import institutionLogoCentralFlorida from './images/institutionLogoCentralFlorida.png'
import institutionLogoUniversityPlymouth from './images/institutionLogoUniversityPlymouth.png'
import institutionLogoMadisonCollege from './images/institutionLogoMadisonCollege.png'
import institutionLogoCalifornia from './images/institutionLogoCalifornia.png'
import institutionLogoMarquette from './images/institutionLogoMarquette.png'

import './InstitutionLogos.scss'

const InstitutionLogos: React.SFC<{}> = () => (
  <FlexBox justify={Justification.CENTER} className="institution-logos">
    <ImageContainer src={institutionLogoNebraska} alt="University of Nebraska Lincoln" rowNumber={RowNumber.SIX} />
    <ImageContainer src={institutionLogoCentralFlorida} alt="University of Central Florida" rowNumber={RowNumber.SIX} />
    <ImageContainer src={institutionLogoUniversityPlymouth} alt="University of Plymouth" rowNumber={RowNumber.SIX} />
    <ImageContainer
      src={institutionLogoMadisonCollege}
      alt="Madison Area Technical College"
      rowNumber={RowNumber.SIX}
    />
    <ImageContainer
      src={institutionLogoCalifornia}
      alt="California State University Long Beach"
      rowNumber={RowNumber.SIX}
    />
    <ImageContainer src={institutionLogoMarquette} alt="Marquette University" rowNumber={RowNumber.SIX} />
  </FlexBox>
)

export default InstitutionLogos
