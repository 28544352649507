import React from 'react'

import Jumbotron from '../components/Jumbotron/Jumbotron'
import ContentContainer from '../components/ContentContainer/ContentContainer'
import Heading, { HeadingLevel, HeadingStyle } from '../components/Heading/Heading'
import Button from '../components/Button/Button'
import FlexBox, { Justification } from '../components/FlexBox/FlexBox'
import InstitutionLogos from '../components/InstitutionLogos/InstitutionLogos'
import TitledContent from '../components/TitledContent/TitledContent'
import FullWidthSection, { PaddingOption } from '../components/FullWidthSection/FullWidthSection'
import Paragraph from '../components/Paragraph/Paragraph'
import MainLayout from '../layouts/MainLayout'
import { COMPANY_NAME } from '../constants'

import jumboImage from './Index/images/home-jumbotron.png'
import gleanLogin from './Index/images/glean-login.png'
import gleanScreenshots from './Index/images/glean-screenshots.png'
import antSupport from './Index/images/ant-support.png'
import gleanBlue from './Index/images/glean-mobile.png'
import gleanLaptop from './Index/images/glean-laptop.png'
import antStarting from './Index/images/ant-start.png'
import antKnowledge from './Index/images/ant-knowledge.png'

import './Index/Index.scss'
import Card from '../components/Card/Card'
import CardContainer from '../layouts/CardContainer/CardContainer'
import CallToAction from '../components/CallToAction/CallToAction'

const Home: React.FunctionComponent = () => (
  <MainLayout title={COMPANY_NAME}>
    <Jumbotron title="Sonocent Audio Notetaker" image={jumboImage}>
      <Paragraph>
      Welcome to the home of Audio Notetaker - our legacy note taking solution for students and institutions. 
      Please note, Audio Notetaker is no longer available to purchase.
      </Paragraph>
    </Jumbotron>

    <main>
      <CardContainer>
        <Card title="Upgrade to Glean" image={gleanScreenshots}>
          <Paragraph>
            Glean is our new cloud-based audio note taking software which empowers students to become better
            learners.
          </Paragraph>
          <Paragraph>
            Glean takes the best of Sonocent Audio Notetaker and delivers a host of new features designed
            to provide students with lifelong study skills.
          </Paragraph>
          <FlexBox justify={Justification.EVENLY} vertical>
            <Button to="https://glean.co/why-upgrade-to-glean/" forGlean>Upgrade Personal Account</Button>
            <Button to="https://glean.co/why-upgrade-to-glean-for-education/" forGlean>Upgrade Institution-wide</Button>
          </FlexBox>
        </Card>
        <Card title="Audio Notetaker Support" image={antSupport}>
          <Paragraph>
            If you're still an Audio Notetaker user, we've still got all the resources and support you need to get
            the most from our legacy app.
          </Paragraph>
          <Paragraph>
            Visit the pages linked below to access self-serve guides and manage
            your Audio Notetaker licences.
          </Paragraph>
          <FlexBox justify={Justification.EVENLY} vertical>
            <Button to="https://support-hub.sonocent.com/">Support Resources</Button>
          </FlexBox>
        </Card>
      </CardContainer>

      <FullWidthSection paddingTop={PaddingOption.MEDIUM} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <TitledContent title="Sonocent is now Glean" center>
            <Paragraph className="home__paragraph--left-aligned">
              We originally created Sonocent Audio Notetaker to provide accessible audio notes for people who found working with text notes difficult.
            </Paragraph>

            <Paragraph className="home__paragraph--left-aligned">
              Now, as Glean, we've taken everything we&apos;ve learned from assistive technology and made the learning process more inclusive for students of all abilities.
            </Paragraph>

            <img src={gleanBlue} alt="Cell phone hovering over a notepad illustrating digital transformation" className="home__image--large" />

            <Button to="https://glean.co/" className="home__button--glean" forGlean>Meet Glean</Button>
          </TitledContent>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.MEDIUM} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <Heading headingLevel={HeadingLevel.H2} headingStyle={HeadingStyle.TITLE} className="home__title--centred">
            Why upgrade to Glean
          </Heading>
          <FlexBox justify={Justification.BETWEEN} wrap={false} className="audio-notetaker__admin-tools">
            <FlexBox vertical justify={Justification.START} className="audio-notetaker__admin-tools__content">
              <Paragraph>
                Our intuitive note-taking web application, Glean, makes it easier than ever for students to take control of their own learning through the power of great note taking.
              </Paragraph>
              <Paragraph>
                Beyond note taking, Glean gives students of all abilities a framework for learning that will last a lifetime.
              </Paragraph>
              <Button to="https://glean.co/why-upgrade-to-glean/" forGlean>Personal</Button>
              <Button to="https://glean.co/why-upgrade-to-glean-for-education/" forGlean>Institutions</Button>
            </FlexBox>
            <FlexBox justify={Justification.CENTER} className="audio-notetaker__admin-tools__image-container" vertical>
              <img src={gleanLogin} alt="A mobile phone and laptop, both showing the Glean login screen" />
            </FlexBox>
          </FlexBox>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.MEDIUM} paddingBottom={PaddingOption.SMALL}>
        <ContentContainer>
          <TitledContent title="Here's some of the institutions seeing amazing results with Glean" center>
            <InstitutionLogos />
          </TitledContent>
          <FlexBox justify={Justification.CENTER} vertical className="home__pills-flexbox">
            <Button to="https://glean.co/resources/case-studies-and-user-stories/">View case studies</Button>
          </FlexBox>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.MEDIUM} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <TitledContent title="Audio Notetaker Resources" center>
            <Paragraph>If you&apos;re still a Sonocent Audio Notetaker customer, you can access support resources for our legacy note taking app below</Paragraph>

            <CallToAction title="Getting Started" image={antStarting} alt="">
              <Paragraph>If you're new to Sonocent Audio Notetaker, visit here to discover resources to help you get up and running with the software.</Paragraph>
              <Button to="https://try.sonocent.com/sprint/" className="home__button--wide">Get Started</Button>
            </CallToAction>

            <CallToAction title="Knowledge Base" image={antKnowledge} alt="">
              <Paragraph>Visit our Knowledge Base to access FAQs, user guides, and more to help you get the most out of Sonocent Audio Notetaker.</Paragraph>
              <Button to="https://desk.zoho.com/portal/gleanco/en/kb/sonocent-ltd/faq" className="home__button--wide">Visit Knowledge Base</Button>
            </CallToAction>

          </TitledContent>
        </ContentContainer>
      </FullWidthSection>

      <FullWidthSection paddingTop={PaddingOption.MEDIUM} paddingBottom={PaddingOption.NONE}>
        <ContentContainer>
          <TitledContent title="Discover more about Glean" center>
            <Paragraph>We&apos;re trusted by over 450 institutions worldwide, where thousands of students are using Glean to become better learners.</Paragraph>

            <img src={gleanLaptop} alt="A laptop illustrating studying with Glean" className="home__image--large" />

            <Button to="https://glean.co/resources/case-studies-and-user-stories/" className="home__button--glean" forGlean>
              Hear from our users
            </Button>
          </TitledContent>
        </ContentContainer>
      </FullWidthSection>
    </main>
  </MainLayout>
)

export default Home
