import React from 'react'
import TitledContent from '../TitledContent/TitledContent'
import './Card.scss'

export interface CardProps {
  title: string
  image?: string
  alt?: string
}

const Card: React.FunctionComponent<CardProps> = ({ title, image, alt, children }) => (
  <TitledContent title={title} className="card" titleClassName="card__title">
    {image && <div className="card__image-container"><img src={image} className="card__image" alt={alt} /></div>}
    {children}
  </TitledContent>
)

export default Card
