import React from 'react'
import FlexBox, { Justification } from '../FlexBox/FlexBox'
import Heading, { HeadingLevel, HeadingStyle } from '../Heading/Heading'
import './CallToAction.scss'

export interface CallToActionProps {
  title: string
  image: string
  alt: string
}

const CallToAction: React.FunctionComponent<CallToActionProps> = ({ title, image, alt, children }) => (
  <FlexBox justify={Justification.BETWEEN} wrap={false} className="call-to-action">
    <div className="call-to-action__image-container">
      <img src={image} alt={alt} className="call-to-action__image" />
    </div>
    <div className="call-to-action__content">
      <Heading headingLevel={HeadingLevel.H3} headingStyle={HeadingStyle.TITLE} className="call-to-action__title">{title}</Heading>
      {children}
    </div>
  </FlexBox>
)

export default CallToAction
