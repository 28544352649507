import React from 'react'
import classNames from 'classnames'
import hash from 'object-hash'

import './ImageContainer.scss'
import FlexBox, { Justification } from '../FlexBox/FlexBox'

export enum RowNumber {
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX
}

interface Props {
  src: string
  alt: string
  rowNumber?: RowNumber
  padding?: boolean
  description?: string
}

const ImageContainer: React.FunctionComponent<Props> = ({ src, alt, rowNumber, padding = false, description }) => {
  const id = description ? hash({ src, alt, description }) : undefined
  const hasItemsPerRow = rowNumber !== undefined

  return (
    <FlexBox
      justify={Justification.CENTER}
      className={classNames('image-container', {
        'image-container--padding': hasItemsPerRow || padding,
        'image-container--row-2': rowNumber === RowNumber.TWO,
        'image-container--row-3': rowNumber === RowNumber.THREE,
        'image-container--row-4': rowNumber === RowNumber.FOUR,
        'image-container--row-5': rowNumber === RowNumber.FIVE,
        'image-container--row-6': rowNumber === RowNumber.SIX
      })}
    >
      <img src={src} alt={alt} className="image-container__image" aria-describedby={id} />
      {description && (
        <p id={id} className="image-container__description" aria-hidden="true">
          {description}
        </p>
      )}
    </FlexBox>
  )
}

export default ImageContainer
